@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: Agrandir;
  src: url('./content/fonts/Agrandir-Regular.ttf');
}

@font-face {
  font-family: PoppinsB;
  src: url('./content/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: PoppinsM;
  src: url('./content/fonts/Poppins-Medium.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: solid 1px lime !important; */
}

:root {
  --bg: #FFF;
  --primary: #7159e3;
}

#app {
  font-family: Agrandir, sans-serif;
}

#pdfContent {
  font-family: PoppinsB, sans-serif;
}

.font-poppinsM {
  font-family: PoppinsM, sans-serif;
}

.font-poppins {
  font-family: PoppinsB, sans-serif;
}

.text-balance {
  text-wrap: balance;
}

#mobile-navbar {
  animation: slice-down 500ms forwards ease;
}

.article-editor > div {
  width: 100% !important;
}

@keyframes slice-down {
  0% {transform: translateY(-120%);}
  100% {transform: translateY(0);}
}